<template>
  <el-header>
    <el-affix v-if="$device.isDesktop">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        :ellipsis="false"
        :popper-offset="16"
        @select="handleSelect"
      >
        <el-menu-item index="0">
          <NuxtLink to="/">
            <img
              width="208"
              height="60"
              src="/img/smaildell-logo.png"
              alt="Логотип Smail Dell"
            />
          </NuxtLink>
        </el-menu-item>

        <ClientOnly>
        <div
          style="
            flex-direction: column;
            flex: 1;
            justify-content: center;
            overflow: hidden;
            white-space: nowrap;
          "
          :style="{display: isHeadOverflow ? 'none' : 'flex'}"
        >
      
          <span style="white-space: nowrap">
            <span style="font-weight: 300">Тел:</span
            ><span style="font-weight: 500"><a href="tel:+79052860984" style="color: black;">+7 (905) 286-09-84</a></span>
          </span>
          <span style="white-space: nowrap">
            <span style="font-weight: 300">Email:</span
            ><span style="font-weight: 500">opt@smaildell.ru</span>
          </span>
        </div>
      </ClientOnly>
        <el-menu-item index="1">
          <NuxtLink to="/">О комании</NuxtLink>
        </el-menu-item>
        <el-menu-item index="2">
          <NuxtLink to="/catalog">Каталог</NuxtLink>
        </el-menu-item>
        <el-menu-item index="3">
          <NuxtLink to="/prices">Цены</NuxtLink>
        </el-menu-item>
        <el-menu-item index="4">
          <NuxtLink to="/contact">Контакты</NuxtLink>
        </el-menu-item>
        <el-menu-item v-if="isAdmin" index="5">
          <NuxtLink to="/private">
            <span style="color: red">Админка</span>
          </NuxtLink>
        </el-menu-item>

        <div v-if="isAdmin" class="flex items-center">
          <el-tag> {{ currentUser?.login }}</el-tag>
          <div style="text-align: center">
            <el-button link type="info" @click="handleLogout">
              Выйти
            </el-button>
          </div>
        </div>
      </el-menu>
    </el-affix>
    <div v-else>
      <p
        style="
          font-size: 22px;
          text-align: center;
          padding: 0;
          margin: 0;
          font-weight: 800;
        "
      >
        Smail Dell
      </p>

      <div style="display: flex; justify-content: center">
        <NuxtLink to="/" class="menu-link">О комании</NuxtLink>
        <span class="menu-spacer">|</span>
        <NuxtLink to="/catalog" class="menu-link">Каталог</NuxtLink>
        <span class="menu-spacer">|</span>
        <NuxtLink to="/prices" class="menu-link">Цены</NuxtLink>
        <span class="menu-spacer">|</span>
        <NuxtLink to="/contact" class="menu-link">Контакты</NuxtLink>
      </div>

      <div
        style="
          white-space: nowrap;
          text-align: right;
          border-bottom: 1px solid #dddfe6;
        "
      >
        <span style="font-weight: 300">Тел:</span>
        <span style="font-weight: 500"><a href="tel:+79052860984" style="color: black;">+7 (905) 286-09-84</a></span>
      </div>
    </div>
  </el-header>
</template>

<script setup>
import { ref } from "vue";
import { useWindowSize } from "@vueuse/core";

const router = useRouter(); // Инициализируем router для навигации
const route = useRoute();
const { width } = useWindowSize();

const currentUser = useAuthUser();
const isAdmin = useAdmin();
const { logout } = useAuth();

const isHeadOverflow = ref(false)

watchEffect(() => {

  isHeadOverflow.value = width.value < 900 ? true : false;
});

// Выбор текущего раздела при загрузке страницы
const activeIndex = ref("1");
if (route.fullPath.startsWith("/prices")) {
  console.log("isPrice");
  activeIndex.value = "3";
} else if (route.fullPath.startsWith("/contact")) {
  activeIndex.value = "4";
} else if (route.fullPath.startsWith("/catalog")) {
  activeIndex.value = "2";
} else if (route.fullPath.startsWith("/private")) {
  activeIndex.value = "5";
} else {
  activeIndex.value = "1";
}

// показывет index текущего раздела
const handleSelect = (key, keyPath) => {
  //console.log(key, keyPath)
};

// Обработчик выхода с навигацией
const handleLogout = async () => {
  try {
    await logout();
    await router.push("/"); // Перенаправление на главную страницу
  } catch (error) {
    console.error("Ошибка при выходе:", error);
  }
};
</script>

<style scoped>
/* Меню справа начиная с "О компании" */
/*noinspection CssUnusedSymbol*/
.el-menu--horizontal > .el-menu-item:nth-child(2) {
  margin-right: auto;
}

a {
  text-decoration: none;
}

.el-menu-demo {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(50%) blur(4px);
}

.menu-spacer {
  padding: 0 3px;
}

.menu-link {
  white-space: nowrap;
  color: green;
  font-weight: 500;
}
</style>
