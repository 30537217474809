<template>
  <el-divider class="divider-bottom" />
  <div class="responsive-container">
    <div class="text">ИП Шапошников С. Н.</div>
    <div class="text">
      Производим с 1989 г.
      <!-- © Продукция защищена авторским правом. -->
    </div>
    <div class="text">
      <!-- Производим с 1989 г.  -->
      © Продукция защищена авторским правом.
    </div>
  </div>
</template>

<style scoped>
.divider-bottom {
  margin-top: 0;
  margin-bottom: 10px;
}

.responsive-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 10px;
}

.text {
  white-space: nowrap;
  text-align: left;
  text-align: center;
  font-size: 0.9em;
}

</style>

<!-- <div>
            <span style="font-weight: 300;">Тел:</span> <span style="font-weight: 500;">+7 (905) 286-09-84</span><br>
            <span style="font-weight: 300;">Email:</span> <span style="font-weight: 500;">opt@smaildell.ru</span>
        </div> -->
