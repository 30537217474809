<template>
    <el-container>
        <AppHeader />
        <el-main class="main_content">
            <slot />
        </el-main>
        <el-footer>
            <AppFooter />
        </el-footer>
    </el-container> 
</template>

<style scoped>
.main_content {
    padding-top: 8px;
    overflow: hidden;
}
</style>